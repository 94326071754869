import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import AppointmentContainer from "../containers/home/appointment";
import GoogleMapContainer from "../containers/global/map";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const AppointmentPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="LERC – Laser Eye &amp; Research Center" />
                <div className="wrapper home-default-wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">

                        <AppointmentContainer />
                        <GoogleMapContainer />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AppointmentPage;
