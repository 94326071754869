import React from "react";

const GoogleMap = () => {
    return (
        <iframe title="this is title" className="contact-map" aria-hidden="false" src="https://maps.google.com/maps?q=Dubai%20Healthcare%20City,%20Building%2027,%20Block%20B,%20Clinic%20402%20-%20Dubai%20-%20United%20Arab%20Emirates+LASER%20EYE%20CARE%20AND%20RESEARCH%20CENTER)&amp;output=embed"></iframe>

        // <iframe
        //     title="this is title"
        //     className="contact-map"
        //     src="https://maps.google.com/maps?q=25.2316089,55.3263923&z=16&output=embed"
        //     // src="https://maps.google.com/maps/embed?dir//LASER+EYE+CARE+AND+RESEARCH+CENTER+Dubai+Healthcare+City,+Building+27,+Block+B,+Clinic+402+Dubai+United+Arab+Emirates/@25.2316089,55.3263923,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x3e5f5d380c7020b9:0x54082e722bfa5231"
        //     // src="https://www.google.com/maps/embed?place/LASER+EYE+CARE+AND+RESEARCH+CENTER/@25.2316774,55.3266153,15.04z/data=!4m5!3m4!1s0x0:0x54082e722bfa5231!8m2!3d25.2316089!4d55.3263923"
        //     // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2136.986005919501!2d-25.231581975348!3d40.55.326401011778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e4a1c884e5%3A0x24fe1071086b36d5!2sThe%20Atrium!5e0!3m2!1sen!2sbd!4v1585132512970!5m2!1sen!2sbd"
        //     aria-hidden="false"
        // ></iframe>
    );
};

export default GoogleMap;
