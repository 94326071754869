import React from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../accordion";
import PropTypes from "prop-types";
import AccordianData from "../../data/accordion.json";

const AccordionWrap = ({ i }) => {
    return (
        <Accordion classOption="accordion-style2 no-bg">
            {AccordianData[i].AccordianInfo &&
                AccordianData[i].AccordianInfo.map((accordianInfo, key) => {
                    return (<AccordionItem key={key} id={accordianInfo.id}>
                        <AccordionTitle id={accordianInfo.id}>
                            <span dangerouslySetInnerHTML={{ __html: accordianInfo.header }}></span>
                        </AccordionTitle>
                        <AccordionContent id={accordianInfo.id}>

                            <span dangerouslySetInnerHTML={{ __html: accordianInfo.content }}></span>
                        </AccordionContent>
                    </AccordionItem>);
                })}

        </Accordion>

    );
};

AccordionWrap.propTypes = {
    i: PropTypes.string,
};
export default AccordionWrap;
