import React, { useState } from 'react'
import PropTypes from "prop-types";

function MoreItems({ desc }) {
    const [visible, setVisible] = useState(90);
    const [loadcontent, setLoadContent] = useState(true)

    function handleKeyDown(ev) {
        // check keys if you want
        if (ev.keyCode == 13) {
            loadMore()
            loadless()
        }
    }
    // console.log(desc)

    function loadMore(quoteLength) {
        setVisible(quoteLength)
        setLoadContent(false)
    }

    function loadless() {
        setVisible(90)
        setLoadContent(true)
    }

    return (
        <div>
            {/* {desc && desc[0].slice(0, visible)} */}

            <div

                className="desc"
                dangerouslySetInnerHTML={{
                    __html: desc.slice(0, visible),
                }}
            />

            <p onClick={() => loadMore(desc.length)} onKeyDown={() => handleKeyDown()} role='presentation' className={loadcontent ? "more" : "loadmorecontent"} > More...</p>


            <p onClick={() => loadless()} onKeyDown={() => handleKeyDown()} role='presentation' className={loadcontent ? "loadmorecontent" : "more"}>Less...</p>





        </div>
    )
}

MoreItems.propTypes = {
    desc: PropTypes.string,
};
export default MoreItems