import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import SocialIcon from "../../social-icon";

const MobileMenu = ({ show, onClose }) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <div
            className={`offcanvas offcanvas-mobile-menu ${show ? "offcanvas-open" : ""
                }`}
        >
            <div className="inner">
                <div className="border-bottom mb-3 pb-3 text-end">
                    <button className="offcanvas-close" onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className="offcanvas-head mb-3">
                    <div className="header-top-offcanvas">
                        <p className="mb-0">Laser Eye &amp; Research Center</p>
                        <p>
                            <i className="icofont-google-map"></i>{" "}
                            <span>ADDRESS:</span> Dubai Healthcare City, Building 27, Block B, Clinic 402 - Dubai
                        </p>
                    </div>
                </div>
                <nav className="offcanvas-menu">
                    <ul>
                        {/* <li>
                            <NavLink exact to={process.env.PUBLIC_URL + "/"}>
                                <span className="menu-text">Home</span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        exact
                                        to={process.env.PUBLIC_URL + "/"}
                                    >
                                        Home 1
                                    </NavLink>
                                </li>
                            </ul>
                        </li> */}
                        <li>
                            <NavLink exact to={process.env.PUBLIC_URL + "/"}>
                                <span className="menu-text">Laser Eye Surgery </span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/service"}
                                    >
                                        What is Laser Eye Surgery?
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/lasereyesurgery/who-qualifies"}
                                    >
                                        Am I Suitable?
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/lasereyesurgery/costs"}
                                    >
                                        Laser Eye Surgery Costs
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/lasereyesurgery/lasik"}
                                    >
                                        UltraLasik
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/lasereyesurgery/lasek"}
                                    >
                                        LASEK
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/lasereyesurgery/femto"}
                                    >
                                        Femto LASIK
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/lasereyesurgery/relex-smile"}
                                    >
                                        ReLEx SMILE
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/lasereyesurgery/recovery-after-care"}
                                    >
                                        Recovery &amp; After Care
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/lasereyesurgery/risks"}
                                    >
                                        Risks
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/lasereyesurgery/laser-eye-faqs"
                                        }
                                    >
                                        Laser Eye FAQs
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink exact to={process.env.PUBLIC_URL + "/lensreplacement/lens-replacement"}>
                                <span className="menu-text"> Lens Replacement </span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/lensreplacement/lens-replacement"}
                                    >
                                        Lens Replacement
                                    </NavLink>

                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={process.env.PUBLIC_URL + "/lensreplacement/lens-replacement"}
                                        >
                                            what is Lens Replacement Surgery?
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/lensreplacement/am-i-suitable"
                                            }
                                        >
                                            Am i Suitable?
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/lensreplacement/lens-replacement-cost"
                                            }
                                        >
                                            Lens Replacement Costs
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/lensreplacement/clarivu"
                                            }
                                        >
                                            Clarivu
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/lensreplacement/replacement-risks"
                                            }
                                        >
                                            Risks
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/lensreplacement/lens-replacement-faqs"
                                            }
                                        >
                                            Lens Replacement FAQs
                                        </NavLink>
                                    </li>
                                </li>

                            </ul>
                        </li>

                        <li>
                            <NavLink exact to={process.env.PUBLIC_URL + "/cataracts/cataractsurgery"}>
                                <span className="menu-text"> Cataracts</span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/cataracts/cataractsurgery"}
                                    >
                                        What is Cataracts Surgery?
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/cataracts/cataractsurgerycosts"}
                                    >
                                        Cataracts Surgery Costs
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink exact to={process.env.PUBLIC_URL + "/eyeconditions/medical-treatments"}>
                                <span className="menu-text"> Eye Conditions  </span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={process.env.PUBLIC_URL + "/eyeconditions/medical-treatments"}
                                    >
                                        Medical Treatments
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/glaucoma"
                                        }
                                    >
                                        Glaucoma
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/eye-floaters"
                                        }
                                    >
                                        Eye Floaters
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/hyperopia"
                                        }
                                    >
                                        Hyperopia
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/cataracts"
                                        }
                                    >
                                        Cataracts
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/retinal-detachment"
                                        }
                                    >
                                        Retinal Detachment
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/presbyopia"
                                        }
                                    >
                                        Presbyopia
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/astigmatism"
                                        }
                                    >
                                        Astigmatism
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/droopyeyelid"
                                        }
                                    >
                                        Droopy Eyelid
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/macularhole"
                                        }
                                    >
                                        Macular Hole
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/maculardegeneration"
                                        }
                                    >
                                        Macular Degeneration
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/myopia"
                                        }
                                    >
                                        Myopia
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="sub-menu-link"
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/eyeconditions/diabetic"
                                        }
                                    >
                                        Diabetic Related Eye Conditions
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/blogs"}>
                                <span className="menu-text">Gallery</span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/blogs"}
                                    >
                                        Gallery
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/blog-details/1"
                                        }
                                    >
                                        Blog details
                                    </NavLink>
                                </li> */}
                            </ul>
                        </li>


                        <li>
                            <NavLink exact to={process.env.PUBLIC_URL + "/about/aboutus"}>
                                <span className="menu-text"> About </span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/about/consultants"}
                                    >
                                        Our Consultants
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/about/whychooseus"}
                                    >
                                        Why Choose Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/about/facilities"}
                                    >
                                        Our facilities
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/about/technology"}
                                    >
                                        Our technology
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/about/reviews"}
                                    >
                                        Patient reviews
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/contact"}>
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <div className="offcanvas-social my-4">
                    <ul>
                        <li>
                            <SocialIcon
                                path="https://twitter.com/LERCDUBAI"
                                icon="icofont-twitter"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.facebook.com/LaserEyeCareDubai/"
                                icon="icofont-facebook"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.instagram.com/lercdubai/"
                                icon="icofont-instagram"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://api.whatsapp.com/send?phone=971555838367&text=Hello,%20I%20would%20like%20to%20book%20a%20free%20consultation%20for%20the%20LASIK%20treatment."
                                icon="icofont-whatsapp"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.youtube.com/rpwg7nslb9U"
                                icon="icofont-play-alt-1"
                            />
                        </li>
                    </ul>
                </div>

                <ul className="media-wrap">
                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-clock-time"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">
                                working hours
                            </span>
                            <span className="media-heading">
                                SAT - THU: 9.00 - 17.00{" "}
                            </span>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-ui-call"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">
                                hotline 24/7
                            </span>
                            <a
                                className="media-heading"
                                href="tel:+0962-58-58-258"
                            >
                                +971 50 946 2020
                            </a>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-envelope"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">email us</span>
                            <a
                                className="media-heading"
                                href="mailto:info@lasereyecenter.ae"
                            >
                                info@lasereyecenter.ae
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
