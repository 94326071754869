import React from "react";
import Layout from "../../layouts/index.jsx";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import PageTitleContainer from "../../containers/global/page-title/index.jsx";
import LeftContentContainer from "../../containers/global/left-content/index.jsx";
import CenterContentContainer from "../../containers/global/center-content/index.jsx";
import CallToAction from "../../containers/global/call-to-action/index.jsx";
import ScrollToTop from "../../components/scroll-to-top";
import SEO from "../../components/seo";

const DiabeticPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="LERC – Diabetic" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <PageTitleContainer
                            image="img/slider/natureeyedrop.png"
                            title="<span></span>"

                        />
                        <LeftContentContainer surgeryClassOption="noborder-line" page="Diabetic" i="0" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Diabetic" i="1" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Diabetic" i="2" />


                        <CallToAction />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default DiabeticPage;
