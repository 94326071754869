import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const LaserEyeSurgeryItem = ({ data }) => {
    return (
        <div className={data.classOption}>
            <div className="surgery-content">
                <div className="thumb">
                    <img
                        src={process.env.PUBLIC_URL + data.image}
                        alt="hope-HasTech"
                    />
                </div>
                <div className="content">
                    <div className="member-info">
                        <h4 className="name">{data.name}</h4>
                        <p>{data.excerpt}</p>

                    </div>
                </div>
            </div>
            {data.btnName !== 'nobutton' && <div className="surgery-footer">
                <Link className={data.btnClassOption} to={data.redirect}>

                    {data.btnName}
                </Link>
            </div>}
        </div>
    );
};

LaserEyeSurgeryItem.propTypes = {
    data: PropTypes.object
};

export default LaserEyeSurgeryItem;
