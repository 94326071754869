import { useForm } from "react-hook-form";
import React, { Fragment, useRef } from "react";
import emailjs from 'emailjs-com';
const Newsletter = () => {
    const { register, errors } = useForm({
        mode: "onBlur",
    });
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault(); // Prevents default refresh by the browser
        console.log(e)
        emailjs.sendForm(`service_zjz2una`, 'template_qoc7bha', form.current, 'user_zrCsJWn3wHOZ8xjyoSDC6')
            .then((result) => {
                console.log(result)
                alert("Message Sent, We will get back to you shortly", result.text);
            },
                (error) => {
                    console.log(error)
                    alert("An error occurred, Please try again", error.text);
                });
    };
    return (
        <Fragment >
            <form className="newsletter-form input-btn-group" ref={form} onSubmit={sendEmail} method="POST">
                
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                placeholder="test@yourmail.com"
                                ref={register({
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "invalid email address",
                                    },
                                })}
                            />
                            {errors.email && <p>{errors.email.message}</p>}
                    
                <button
                    className="btn btn-theme"
                    type="submit"
                >
                    <i className="icofont-long-arrow-right"></i>
                </button>
            </form>
        </Fragment>
    );
};


export default Newsletter;

