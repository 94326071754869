import React from "react";
import Layout from "../../layouts/index.jsx";
import Header from "../../layouts/header";
import IntroContainer from "../../containers/home/intro";
import TeamContainer from "../../containers/home/team";
import GoogleMapContainer from "../../containers/global/map";
import Footer from "../../layouts/footer";
import ScrollToTop from "../../components/scroll-to-top";
import SEO from "../../components/seo";

const ConsultantsPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="LERC – Laser Eye &amp; Research Center" />
                <div className="wrapper home-default-wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <IntroContainer />
                        <TeamContainer />
                        <GoogleMapContainer />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ConsultantsPage;
