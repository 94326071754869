
const BlogsListItem = () => {
    return (
        <section className="grid-section">
            <div className="header-section">
                <div className="className-for">
                    From Gallery
                </div>
            </div>
            <article className="blog-card">
                <div className="blog-card__image">
                    <iframe title="2" src="https://youtube.com/embed/rpwg7nslb9U" height="350px" width="480px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                </div>

            </article>


            <article className="blog-card">
                <div className="blog-card__image">
                    <iframe title="2" src="https://youtube.com/embed/CbMZq6XTiJU" height="350px" width="480px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </article>

            <article className="blog-card">
                <div className="blog-card__image">
                    <iframe title="2" src="https://youtube.com/embed/xI7CAb8dEHg" height="350px" width="480px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <iframe title="3" src="https://www.youtube.com/embed/VSVLoWiYr9o" height="350px" width="480px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <iframe title="3" src="https://www.youtube.com/embed/Nf2VVJeUGbQ" height="350px" width="480px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </article>

            <article className="blog-card">
                <div className="blog-card__image">
                    <iframe title="2" src="https://youtube.com/embed/yT50k-wIPhk" height="350px" width="480px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </article>

            <article className="blog-card">
                <div className="blog-card__image">
                    <iframe title="4" src="https://youtube.com/embed/Z6VyLCOySXA" height="350px" width="480px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <iframe title="4" src="https://youtube.com/embed/Y1fsyvwb3sU" height="350px" width="480px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <iframe title="4" src="https://youtube.com/embed/ixgDxHRc7HY" height="350px" width="480px" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/gallery/gallery2.jpeg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/gallery/gallery3.jpeg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/gallery/gallery4.jpeg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/gallery/gallery.jpeg" className="img-responsive" alt="" />
                </div>

            </article>
            {/* <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/laser2.jpeg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/laser3.jpeg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/laser4.jpeg" className="img-responsive" alt="" />
                </div>

            </article> */}
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.03.09.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.04.27.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.08.03.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.03.02.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.07.13.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.03.16.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            {/* <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.03.02.jpg" className="img-responsive" alt="" />
                </div>

            </article> */}
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.03.23.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.03.30.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.04.20.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.08.17.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.07.20.jpg" className="img-responsive" alt="" />
                </div>

            </article>

            {/* <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.03.02.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.03.02.jpg" className="img-responsive" alt="" />
                </div>

            </article>
            <article className="blog-card">
                <div className="blog-card__image">
                    <img src="img/portfolio/2021.03.02.jpg" className="img-responsive" alt="" />
                </div>

            </article> */}

        </section>
    );
};


export default BlogsListItem;
