import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                    Dubai Healthcare City, <span>Dubai</span>
                </h2>
                <p>

                </p>
                <Link to="/">
                    <span>Email:</span> info@lasereyecenter.ae
                </Link>
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>Call directly:</h6>
                    <p>+971 50 946 2020</p>
                </div>
                <div className="info-tem">
                    <h6>Brand Offices:</h6>
                    <p>
                        Dubai Healthcare City, Building 27, Block B, Clinic 402 - Dubai
                    </p>
                </div>
                <div className="info-tem mb-0">
                    <h6>Work Hours:</h6>
                    <p>Sat - Thu: 9.00 - 17.00, Friday closed</p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;
