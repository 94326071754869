

import { useForm } from "react-hook-form";
import React, { Fragment, useRef } from "react";
import emailjs from 'emailjs-com';
const ContactForm = () => {
    const { register, errors } = useForm({
        mode: "onBlur",
    });
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault(); // Prevents default refresh by the browser
        console.log(e)
        emailjs.sendForm(`service_zjz2una`, 'template_qoc7bha', form.current, 'user_zrCsJWn3wHOZ8xjyoSDC6')
            .then((result) => {
                console.log(result)
                alert("Message Sent, We will get back to you shortly", result.text);
            },
                (error) => {
                    console.log(error)
                    alert("An error occurred, Please try again", error.text);
                });
    };
    return (
        <Fragment >
            <form ref={form} onSubmit={sendEmail} method="POST"
                className="contact-form-wrapper">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Enter your name..."
                                ref={register({ required: "Name is required" })}
                            />
                            {errors.name && <p>{errors.name.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                placeholder="demo@yourmail.com"
                                ref={register({
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "invalid email address",
                                    },
                                })}
                            />
                            {errors.email && <p>{errors.email.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="tel"
                                name="mobile"
                                placeholder="Phone"
                                ref={register({
                                    required: "Phone is required",
                                })}
                            />
                            {errors.mobile && <p>{errors.mobile.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group datepicker-group">
                            {/* <label
                                htmlFor="datepicker"
                                className="form-label icon icofont-calendar"
                            > */}
                            <input
                                className="form-control"
                                id="datepicker"
                                type="date"
                                name="date"
                                placeholder="Date"
                                ref={register({
                                    required: "Date is required",
                                })}
                            />
                            {/* </label> */}
                            {errors.date && <p>{errors.date.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-0">
                            <textarea
                                name="message"
                                rows="7"
                                placeholder="Your message here..."
                                ref={register({
                                    required: "Message is required",
                                })}
                            ></textarea>
                            {errors.message && <p>{errors.message.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-0">
                            <button className="btn btn-theme" type="submit">
                                Make an appointment
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};


export default ContactForm;
