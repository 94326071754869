
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const Testimonial = () => {
    const [container, setContainer] = useState('');
    const [container2, setContainer2] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widgets.doctify.com/get-script?widget_container_id=0dpw6l5b&type=horizontal-widget&tenant=athena-uae&language=en&profileType=practice&layoutType=layoutXL&slug=laser-eye-care-and-research-center&background=white'
        script.async = true;
        script.defer = true;
        script.type = 'text/javascript';
        document.body.appendChild(script);
        script.onload = () => {
            setContainer('0dpw6l5b')

        }
        const script2 = document.createElement('script');
        const url2 = "https://widgets.doctify.com/get-script?widget_container_id=0cu6gmvg&type=carousel-widget&tenant=athena-uae&language=en&profileType=practice&layoutType=layoutA&slug=laser-eye-care-and-research-center&background=white&itemBackground=ffffff&outerFrame=true&itemFrame=true";
        script2.src = url2
        script2.async = true;
        script2.defer = true;
        script2.type = 'text/javascript';
        document.body.appendChild(script2);
        script2.onload = () => {
            setContainer2('0cu6gmvg')

        }
        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <div className="testimonial-item">
            {/* <div className="thumb">
            <img
                src=""
                alt="testimonial"
            />
        </div>
        <div className="client-content">
           //<p dangerouslySetInnerHTML={{ __html: data.excerpt }}></p> 
        </div> */}
            <div className="client-info">
                <div className="desc">
                    <h4 className="name">Our Customers really love LERC</h4>
                    <h4 className="client-content">Rated 5 out of 5</h4>
                </div>
            </div>

            {/* <div id={container !== '' ? container : '0dpw6l5b'}></div> */}
            <div id={container2 !== '' ? container2 : '0cu6gmvg'}></div>
        </div>
    );
};

Testimonial.propTypes = {
    data: PropTypes.object,
};

export default Testimonial;
