import PropTypes from "prop-types";

const SectionTitle = ({ subTitle, title, header, description, content, header1, content1, header2, content2, header3, content3, header4, content4, classOption }) => {
    return (
        <div className={`section-title ${classOption}`} data-aos="fade-up">
            <p>{subTitle}</p>
            <h2
                className="header"
                dangerouslySetInnerHTML={{ __html: header }}
            ></h2>
            <span>{description}</span>
            <h2
                className="title"
                dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
            <span dangerouslySetInnerHTML={{ __html: content }}></span>
            <h2
                className="header"
                dangerouslySetInnerHTML={{ __html: header1 }}
            ></h2>
            <span dangerouslySetInnerHTML={{ __html: content1 }}></span>
            <h2
                className="header"
                dangerouslySetInnerHTML={{ __html: header2 }}
            ></h2>
            <span dangerouslySetInnerHTML={{ __html: content2 }}></span>
            <h2
                className="header"
                dangerouslySetInnerHTML={{ __html: header3 }}
            ></h2>
            <span dangerouslySetInnerHTML={{ __html: content3 }}></span>
            <h2
                className="header"
                dangerouslySetInnerHTML={{ __html: header4 }}
            ></h2>
            <span dangerouslySetInnerHTML={{ __html: content4 }}></span>
        </div>
    );
};

SectionTitle.propTypes = {
    subTitle: PropTypes.string,
    title: PropTypes.string,
    header: PropTypes.string,
    header1: PropTypes.string,
    header2: PropTypes.string,
    header3: PropTypes.string,
    header4: PropTypes.string,
    description: PropTypes.string,
    content1: PropTypes.string,
    content2: PropTypes.string,
    content3: PropTypes.string,
    content4: PropTypes.string,
    content: PropTypes.string,
    classOption: PropTypes.string,
};

SectionTitle.defaultProps = {
    classOption: "section-title",
};

export default SectionTitle;
