import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
// import ServiceContainer from "../containers/service-box/index.jsx";
// import TestimonialContainer from "../containers/home/testimonial/index.jsx";
// import BrandContainer from "../containers/global/brand/index.jsx";
// import PricingContainer from "../containers/global/pricing/index.jsx";
import LeftContentContainer from "../containers/global/left-content/index.jsx";
import RightContentContainer from "../containers/global/right-content/index.jsx";
import LeftImageContainer from "../containers/global/left-image/index.jsx";
import CenterContentContainer from "../containers/global/center-content/index.jsx";
import EyeSurgeryContainer from "../containers/global/eye-surgery/index.jsx";
import CallToAction from "../containers/global/call-to-action/index.jsx";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const ServicePage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="LERC – Service" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <PageTitleContainer
                            image="img/slider/lasereye.png"
                            title="<span></span>"
                        />
                        <LeftImageContainer page="Service" i="9" />
                        <EyeSurgeryContainer surgeryClassOption="noborder-line" page="Service" />
                        <LeftContentContainer surgeryClassOption="noborder-line" page="Service" i="10" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Service" i="11" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Service" i="12" />
                        <RightContentContainer page="Service" i="13" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Service" i="14" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Service" i="15" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Service" i="16" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Service" i="17" />
                        {/* <ServiceContainer /> */}
                        {/* <TestimonialContainer /> */}
                        {/* <BrandContainer />
                        <PricingContainer pricingClassOption="noborder-line" /> */}
                        <CallToAction />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ServicePage;
