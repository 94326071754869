import React from "react";
import Layout from "../../layouts/index.jsx";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import PageTitleContainer from "../../containers/global/page-title/index.jsx";
import CenterContentContainer from "../../containers/global/center-content/index.jsx";
import CallToAction from "../../containers/global/call-to-action/index.jsx";
import ScrollToTop from "../../components/scroll-to-top";
import SEO from "../../components/seo";
import BlogAccordion from "../../containers/home/blog-accordion";

const LensReplacementSuitablePage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="LERC – Suitable" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <PageTitleContainer
                            image="img/slider/cataract.jpeg"
                            subTitle="Bests solution for your heatlh"

                        />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Suitable" i="0" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Suitable" i="1" />
                        <CenterContentContainer surgeryClassOption="noborder-line" page="Suitable" i="2" />
                        <BlogAccordion page='Lasik' i='4' title="<span>LASIK FAQs</span>" />

                        <CallToAction />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default LensReplacementSuitablePage;
