const VideoContainer = () => {


    return (

        <div className="hero-slider-area">
            {/* <h1 className="text"></h1> */}
            {/* <video className="video" autoPlay muted loop>
                <source src="/img/lasik/FireworksDubai.mp4" type="video/mp4" />
            </video> */}

            <video className="video" autoPlay muted loop playsInline >
                <source src="//videos.ctfassets.net/hrltx12pl8hq/2I9UHYjePe9xbGXUZk9xfK/48e1c364a290dc5e3a4db78d758f4fab/UHP_Abstract_3.webm" type="video/webm" />
                <source src="//videos.ctfassets.net/hrltx12pl8hq/G178fbNejnLVKODyZCSf5/3a83e469f8dca286fcf1c7002e3c33a5/UHP_Abstract_3.mp4" type="video/mp4" />
            </video>
        </div>
    )
}


export default VideoContainer