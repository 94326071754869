import SectionTitle from "../../../components/section-title";
import LensReplaceData from "../../../data/lens-replacement.json";
import ServiceData from "../../../data/service.json";
import PropTypes from "prop-types";

const LeftImageContainer = ({ page, i }) => {
    return (
        <div className="surgery-area surgery-default-area">
            <div className="container">

                {page === 'Service' && <div className="row align-items-center">

                    <div className="col-12 col-md-3 text-center text-md-right">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + ServiceData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                        <SectionTitle
                            classOption="text-left"
                            title={ServiceData[i].leftImage[0].header}
                            content={ServiceData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'LensReplace' && i === '8' && <div className="row align-items-center">

                    <div className="col-12 col-md-3 text-center text-md-right">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + LensReplaceData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                        <SectionTitle
                            classOption="text-left"
                            title={LensReplaceData[i].leftImage[0].header}
                            content={LensReplaceData[i].leftImage[0].description}
                        />
                    </div>
                </div>}


            </div>
        </div>
    );
};

LeftImageContainer.propTypes = {
    page: PropTypes.string,
    i: PropTypes.string,
};

export default LeftImageContainer;
