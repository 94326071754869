import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";
import ServiceData from "../../../data/service.json";
import QualifiesData from "../../../data/who-qualifies.json";
import LasikData from "../../../data/lasik.json";
import LasekData from "../../../data/lasek.json";
import FemtoData from "../../../data/femto.json";
import RelexSmileData from "../../../data/relex-smile.json";
import RecoveryData from "../../../data/recovery-after-care.json";
import LensReplaceData from "../../../data/lens-replacement.json";
import ReplacementCostData from "../../../data/lens-replacement-cost.json";
import MedicalTreatmentsData from "../../../data/medical-treatments.json";
import GlaucomaData from "../../../data/glaucoma.json";
import FloatersData from "../../../data/eyefloaters.json";
import HyperopiaData from "../../../data/hyperopia.json";
import RetinalDetachmentData from "../../../data/retinal-detachment.json";
import PresbiopiaData from "../../../data/presbyopia.json";
import AstigmatismData from "../../../data/astigmatism.json";
import DroopyEyelidData from "../../../data/droopyeyelid.json";
import MacularHoleData from "../../../data/macularhole.json";
import MacularDegenerationData from "../../../data/maculardegeneration.json";
import MyopiaData from "../../../data/myopia.json";
import DiabeticData from "../../../data/diabetic.json";
import PropTypes from "prop-types";

const LeftContentContainer = ({ page, i }) => {
    return (
        <div className="surgery-area surgery-default-area">
            <div className="container">
                {page === 'Home' && <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={HomeData[i].leftContent[0].header}
                            content={HomeData[i].leftContent[0].description}
                        />
                    </div>
                    <div className="col-12 col-md-6 text-right">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + HomeData[i].leftContent[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                </div>}
                {page === 'Service' && <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={ServiceData[i].leftContent[0].header}
                            content={ServiceData[i].leftContent[0].description}
                        />
                    </div>
                    <div className="col-12 col-md-6 text-right">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + ServiceData[i].leftContent[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                </div>}
                {page === 'Qualifies' && i === '1' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + QualifiesData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={QualifiesData[i].leftImage[0].header}
                            content={QualifiesData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Qualifies' && i === '5' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + QualifiesData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={QualifiesData[i].leftImage[0].header}
                            content={QualifiesData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Lasik' && i === '0' && <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={LasikData[i].leftImage[0].header}
                            content={LasikData[i].leftImage[0].description}
                        />
                    </div>
                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + LasikData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasik' && i === '1' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + LasikData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={LasikData[i].leftImage[0].header}
                            content={LasikData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Lasik' && i === '6' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + LasikData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={LasikData[i].leftImage[0].header}
                            content={LasikData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Lasek' && i === '0' && <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={LasekData[i].leftImage[0].header}
                            content={LasekData[i].leftImage[0].description}
                        />
                    </div>

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + LasekData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasek' && i === '1' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + LasekData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={LasekData[i].leftImage[0].header}
                            content={LasekData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Lasek' && i === '4' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + LasekData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={LasekData[i].leftImage[0].header}
                            content={LasekData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Femto' && i === '0' && <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={FemtoData[i].leftImage[0].header}
                            content={FemtoData[i].leftImage[0].description}
                        />
                    </div>
                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + FemtoData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                </div>}
                {page === 'Femto' && i === '1' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + FemtoData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={FemtoData[i].leftImage[0].header}
                            content={FemtoData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Femto' && i === '3' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + FemtoData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={FemtoData[i].leftImage[0].header}
                            content={FemtoData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'RelexSmile' && i === '0' && <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={RelexSmileData[i].leftImage[0].header}
                            content={RelexSmileData[i].leftImage[0].description}
                        />
                    </div>
                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + RelexSmileData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                </div>}
                {page === 'RelexSmile' && i === '1' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + RelexSmileData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={RelexSmileData[i].leftImage[0].header}
                            content={RelexSmileData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'RelexSmile' && i === '3' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + RelexSmileData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={RelexSmileData[i].leftImage[0].header}
                            content={RelexSmileData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Recovery' && i === '2' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + RecoveryData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={RecoveryData[i].leftImage[0].header}
                            content={RecoveryData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Recovery' && i === '3' && <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={RecoveryData[i].leftImage[0].header}
                            content={RecoveryData[i].leftImage[0].description}
                        />
                    </div>
                    <div className="col-12 col-md-6 text-right">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + RecoveryData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                </div>}
                {page === 'Recovery' && i === '6' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + RecoveryData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={RecoveryData[i].leftImage[0].header}
                            content={RecoveryData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'LensReplace' && i === '4' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + LensReplaceData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={LensReplaceData[i].leftImage[0].header}
                            content={LensReplaceData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'ReplacementCost' && i === '1' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + ReplacementCostData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={ReplacementCostData[i].leftImage[0].header}
                            content={ReplacementCostData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'MedicalTreatments' && i === '1' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + MedicalTreatmentsData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={MedicalTreatmentsData[i].leftImage[0].header}
                            content={MedicalTreatmentsData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'MedicalTreatments' && i === '2' && <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={MedicalTreatmentsData[i].leftImage[0].header}
                            content={MedicalTreatmentsData[i].leftImage[0].description}
                        />
                    </div>
                    <div className="col-12 col-md-6 text-right">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + MedicalTreatmentsData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                </div>}
                {page === 'MedicalTreatments' && i === '3' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + MedicalTreatmentsData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={MedicalTreatmentsData[i].leftImage[0].header}
                            content={MedicalTreatmentsData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Glaucoma' && i === '3' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + GlaucomaData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={GlaucomaData[i].leftImage[0].header}
                            content={GlaucomaData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Floaters' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + FloatersData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={FloatersData[i].leftImage[0].header}
                            content={FloatersData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Hyperopia' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + HyperopiaData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={HyperopiaData[i].leftImage[0].header}
                            content={HyperopiaData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'RetinalDetachment' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + RetinalDetachmentData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={RetinalDetachmentData[i].leftImage[0].header}
                            content={RetinalDetachmentData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'RetinalDetachment' && i === '3' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + RetinalDetachmentData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={RetinalDetachmentData[i].leftImage[0].header}
                            content={RetinalDetachmentData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'presbyopia' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + PresbiopiaData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={PresbiopiaData[i].leftImage[0].header}
                            content={PresbiopiaData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'presbyopia' && i === '3' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + PresbiopiaData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={PresbiopiaData[i].leftImage[0].header}
                            content={PresbiopiaData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Astigmatism' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + AstigmatismData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={AstigmatismData[i].leftImage[0].header}
                            content={AstigmatismData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'DroopyEyelid' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + DroopyEyelidData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={DroopyEyelidData[i].leftImage[0].header}
                            content={DroopyEyelidData[i].leftImage[0].description}
                        />
                    </div>
                </div>}

                {page === 'MacularHole' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + MacularHoleData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={MacularHoleData[i].leftImage[0].header}
                            content={MacularHoleData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'MacularHole' && i === '2' && <div className="row align-items-center">
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={MacularHoleData[i].leftImage[0].header}
                            content={MacularHoleData[i].leftImage[0].description}
                        />
                    </div>
                    <div className="col-12 col-md-6 text-right">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + MacularHoleData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                </div>}

                {page === 'MacularDegeneration' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + MacularDegenerationData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={MacularDegenerationData[i].leftImage[0].header}
                            content={MacularDegenerationData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Myopia' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + MyopiaData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={MyopiaData[i].leftImage[0].header}
                            content={MyopiaData[i].leftImage[0].description}
                        />
                    </div>
                </div>}
                {page === 'Diabetic' && i === '0' && <div className="row align-items-center">

                    <div className="col-12 col-md-6 text-left">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + DiabeticData[i].leftImage[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div> <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={DiabeticData[i].leftImage[0].header}
                            content={DiabeticData[i].leftImage[0].description}
                        />
                    </div>
                </div>}

            </div>
        </div>
    );
};

LeftContentContainer.propTypes = {
    page: PropTypes.string,
    i: PropTypes.string,
};

export default LeftContentContainer;
